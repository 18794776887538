import { useEffect, useState } from 'react';
import { Modal, Input, Button, Row, Col, message, notification, Select } from 'antd';
import { CaseInfo, OpportunityEditContactDto } from '../../../dto/model';
import { bookingPageService } from '../../../services/booking-page-service';
import { GetAccessToken } from '../../../utils/auth-utils';
import { scopes } from '../../../authConfig';
import { useMsal } from '@azure/msal-react';
import { useSalesChannelState } from '../../../store/header-state';
import { strings } from '../../../lang';
import { EditOutlined } from '@ant-design/icons';

type CustomerInfoEditModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  caseInfo: CaseInfo;
  setCaseInfo: (caseInfo: CaseInfo) => void;
}

const CustomerInfoEditModal = ({ isOpen, setIsOpen, caseInfo, setCaseInfo }: CustomerInfoEditModalProps) => {
  const salesForceRootUrl = (process as any).env.REACT_APP_SALESFORCE_BASE_URL;
  const { instance, inProgress } = useMsal();
  const [editCaseInfo, setEditCaseInfo] = useState<CaseInfo>(caseInfo);
  const { salesChannel } = useSalesChannelState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setEditCaseInfo(caseInfo);
  }, [caseInfo]);

  useEffect(() => {
    const isDisabled = Object
      .keys(editCaseInfo)
      .every(key => editCaseInfo[key as keyof CaseInfo] == caseInfo[key as keyof CaseInfo])
    setIsDisabled(isDisabled);
  }, [editCaseInfo]);

  const handleOk = async () => {
    // If no changes are made return
    if (Object
      .keys(editCaseInfo)
      .every(key => editCaseInfo[key as keyof CaseInfo] == caseInfo[key as keyof CaseInfo])) {
      setIsOpen(false);
      return;
    }

    setIsLoading(true);
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi
    );
    const editContactDto: OpportunityEditContactDto = {
      firstName: editCaseInfo.firstName,
      lastName: editCaseInfo.lastName,
      salutation: editCaseInfo.salutation,
      email: editCaseInfo.email,
      phone: editCaseInfo.contactPhoneNumber,
      opportunityId: caseInfo.opportunityId,
      globalCustomerId: caseInfo.globalCustomerId,
      contactId: caseInfo.contactId,
    };

    const updateResult = await bookingPageService.updateContact(editContactDto, salesChannel, token.accessToken);
    setIsLoading(false);
    if (!updateResult.isSuccess) {
      notification.error({
        message: `Failed to update contact. Errors: ${updateResult.errors}`,
      });
      return;
    }
    notification.info({
      message: 'Contact updated successfully'
    });
    setCaseInfo(editCaseInfo);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setEditCaseInfo(caseInfo);
  };

  const openSalesforceOpportunity = () => {
    window.open(`${salesForceRootUrl}/lightning/r/Opportunity/${caseInfo.opportunityId}/view`);
  }
  const openSalesforceContact = () => {
    window.open(`${salesForceRootUrl}/lightning/r/Contact/${caseInfo.contactId}/view`);
  }
  const removeLeadingZero = (phone: string) => {
    if (phone[0] === '0') {
      setEditCaseInfo({ ...editCaseInfo, contactPhoneNumber: `+49${phone.substring(1)}` });
    }
  }
  return (
    <>
      <Modal
        title={strings.contactEditTitle}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        keyboard={false}
        okText={strings.contactEditSave}
        cancelText={strings.contactEditCancel}
        width={800}
        okButtonProps={{ disabled: isDisabled, loading: isLoading }}
        cancelButtonProps={{ disabled: isLoading }}
      >
        <Row
          gutter={16}
          style={{
            marginBottom: 16,
          }}
        >
          <Col span={4}>
            <label style={{ fontWeight: 'bold' }}>{strings.contactEditSalutation}</label>
            <Select
              disabled
              value={editCaseInfo.salutation}
              onChange={(value) => setEditCaseInfo({ ...editCaseInfo, salutation: value })}
              placeholder="Salutation"
              style={{ width: '100%' }}
            >
              <Select.Option value="Herr">Herr</Select.Option>
              <Select.Option value="Frau">Frau</Select.Option>
              <Select.Option value="Frau/Herr">Frau/Herr</Select.Option>
            </Select>
          </Col>
          <Col span={10}>
            <label style={{ fontWeight: 'bold' }}>{strings.contactEditFirstName}</label>
            <Input
              disabled
              value={editCaseInfo.firstName}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, firstName: e.target.value })}
              placeholder={strings.contactEditFirstName}
            />
          </Col>
          <Col span={10}>
            <label style={{ fontWeight: 'bold' }}>{strings.contactEditLastName}</label>
            <Input
              disabled
              value={editCaseInfo.lastName}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, lastName: e.target.value })}
              placeholder={strings.contactEditLastName}
            />
          </Col>
        </Row>
        <Button
          style={{ marginBottom: 16 }}
          icon={<EditOutlined />}
          onClick={openSalesforceContact}> {strings.contactEditNamesInSalesforce}</Button>

        <Row
          gutter={16}
          style={{
            marginBottom: 16,
          }}
        >
          <Col span={12}>
            <label style={{ fontWeight: 'bold' }}>{strings.contactEditPhone}</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                value={editCaseInfo.contactPhoneNumber}
                onChange={(e) => setEditCaseInfo({ ...editCaseInfo, contactPhoneNumber: e.target.value })}
                placeholder={strings.contactEditPhone}
                style={{ marginRight: 8 }}
              />
              <Button
                disabled={!editCaseInfo.contactPhoneNumber || editCaseInfo.contactPhoneNumber[0] !== '0'}
                onClick={() => { removeLeadingZero(editCaseInfo.contactPhoneNumber) }}>
                {strings.contactEditConvertToAreaCode}
              </Button>
            </div>
          </Col>
        </Row>
        <Row
          gutter={16}
          style={{
            marginBottom: 16,
          }}
        >
          <Col span={12}>
            <label style={{ fontWeight: 'bold' }}>Email</label>
            <Input
              value={editCaseInfo.email}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, email: e.target.value })}
              placeholder="Email"
            />
          </Col>
        </Row>
        <Row
          gutter={16}
          style={{
            marginBottom: 16,
          }}
        >
          <Col span={8}>
            <label style={{ fontWeight: 'bold' }}>{strings.contactEditStreet}</label>
            <Input
              disabled
              value={editCaseInfo.street}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, street: e.target.value })}
              placeholder={strings.contactEditStreet}
            />
          </Col>
          <Col span={8}>
            <label style={{ fontWeight: 'bold' }}>{strings.contactEditZipCode}</label>
            <Input
              disabled
              value={editCaseInfo.zipcode}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, zipcode: e.target.value })}
              placeholder={strings.contactEditZipCode}
            />
          </Col>
          <Col span={8}>
            <label style={{ fontWeight: 'bold' }}>{strings.contactEditCity}</label>
            <Input
              disabled
              value={editCaseInfo.city}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, city: e.target.value })}
              placeholder={strings.contactEditCity}
            />
          </Col>
        </Row>
        <Button
          icon={<EditOutlined />}
          onClick={openSalesforceOpportunity}>{strings.contactEditEditInSalesforce}</Button>
      </Modal >
    </>
  );
};

export default CustomerInfoEditModal;